<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['facialSwelling'])">
      <single-answer-question
        title="label.facialSwelling"
        :value="facialSwelling"
        :options="$options.yesNoOptions"
        dysfunction="skin-redness"
        yes-no
        @input="onFieldChange('facialSwelling', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';

export default {
  name: 'SwellingTemplate',
  components: { SingleAnswerQuestion, QuestionWithErrorWrap },
  yesNoOptions: YES_NO_RESPONSE_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    facialSwelling: {
      type: String,
      default: ''
    }
  }
};
</script>
