import SwellingTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-redness/swelling/SwellingTemplate';

export default {
  title: 'Steps/Redness/Swelling/SwellingTemplate'
};

const createStory = props => () => ({
  components: { SwellingTemplate },
  storyProps: props,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <swelling-template v-bind="$options.storyProps" />
  </div>`
});

export const Base = createStory({
  facialSwelling: 'yes'
});
